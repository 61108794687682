import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ReactCountryFlag from "react-country-flag"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { iconType, inputToggle, leftBarToggle, startTeamNr, TeamList, addTeam, removeTeam, loadPreset, undoButton } from "./Functions";
import { faUser, faRotateLeft } from '@fortawesome/free-solid-svg-icons'

const Counter = ({ value, index, onDecrement, onIncrement, useFlags, hideInput, changeTeamname }) => {

    return (
        <div className="w-1/3 md:w-1/4 lg:w-1/5 mt-3 sm:mt-4 md:mt-3 md:mb-2 flex items-center justify-center flex-wrap flex-row self-start">

            <button className={"tracker-btn relative flex items-center border border-black sm:rounded-3xl p-3 md:px-4 shadow-xl bg-slate-800 hover:bg-slate-700 " + (useFlags && value.alive === 0 ? "bg-gray-800 grayscale-[85%] hover:bg-gray-800" : "")}
                onClick={() => {
                    onDecrement();
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    onIncrement();
                }}
            >
                {
                    useFlags ? (
                        <div>
                            <ReactCountryFlag
                                className="!w-[52px] sm:!w-[76px] xl:!w-[88px] 2xl:!w-[104px]"
                                countryCode={value.countrycode}
                                svg
                                style={{
                                    width: '52px',
                                    height: 'auto',
                                    display: 'inline-block'
                                }}
                                title={value.name}
                            />
                        </div>
                    ) : (
                        <div className={"border tracker-number border-black pt-1 text-2xl sm:text-3xl md:text-4xl bg-blue-500 w-[52px] sm:w-[76px] xl:w-[88px] 2xl:w-[104px] max-w-full h-[39px] sm:h-[57px] xl:h-[66px] 2xl:h-[78px] " + (value.alive === 0 ? "bg-zinc-800" : "")}
                            style={{ backgroundColor: value.hexcode && value.alive > 0 ? value.hexcode : undefined }}>
                            <div className="flex justify-center items-center h-full font-russo">{index + 1}</div>
                        </div>
                    )
                }
                <span className={"text-4xl md:text-5xl inline-block mr-2 " + (value.alive === 0 ? "text-gray-600" : "")}>{value.alive}</span>
            </button>
            {!hideInput &&
                <div>
                    <input name="teamname" maxLength={19} className="hidden lg:inline-block bg-transparent border-b mt-1 border-black max-w-[166px] xl:max-w-[178px] 2xl:max-w-[194px] text-gray-400"
                        onChange={changeTeamname}
                        value={value.name} type="text" />
                </div>
            }
        </div>
    );
};

const Counters = () => {
    const [numTeams, setNumTeams] = useState(() => {
        const savedNumTeams = localStorage.getItem('currentNumTeams');
        return savedNumTeams ? parseInt(savedNumTeams) : 16;
    });

    const defaultTeams = [
        { name: '1 Canada', countrycode: 'CA', alive: 4, hexcode: '#0047A1' },
        { name: '2 Poland', countrycode: 'PL', alive: 4, hexcode: '#15931A' },
        { name: '3 Finland', countrycode: 'FI', alive: 4, hexcode: '#E16209' },
        { name: '4 Denmark', countrycode: 'DK', alive: 4, hexcode: '#2096D1' },
        { name: '5 Brazil', countrycode: 'BR', alive: 4, hexcode: '#4A148C' },
        { name: '6 Germany', countrycode: 'DE', alive: 4, hexcode: '#9F2B14' },
        { name: '7 France', countrycode: 'FR', alive: 4, hexcode: '#486A00' },
        { name: '8 United Kingdom', countrycode: 'GB', alive: 4, hexcode: '#C61A56' },
        { name: '9 Sweden', countrycode: 'SE', alive: 4, hexcode: '#9C6622' },
        { name: '10 Norway', countrycode: 'NO', alive: 4, hexcode: '#820045' },
        { name: '11 Spain', countrycode: 'ES', alive: 4, hexcode: '#D58115' },
        { name: '12 Austria', countrycode: 'AT', alive: 4, hexcode: '#4AB3AF' },
        { name: '13 Turkey', countrycode: 'TR', alive: 4, hexcode: '#6B828D' },
        { name: '14 USA', countrycode: 'US', alive: 4, hexcode: '#F39700' },
        { name: '15 Portugal', countrycode: 'PT', alive: 4, hexcode: '#37474F' },
        { name: '16 Argentina', countrycode: 'AR', alive: 4, hexcode: '#E0648E' },
        { name: '17 South Korea', countrycode: 'KR', alive: 4, hexcode: '#00736D' },
        { name: '18 Japan', countrycode: 'JP', alive: 4, hexcode: '#8A4803' },
        { name: '19 Australia', countrycode: 'AU', alive: 4, hexcode: '#7FB017' },
        { name: '20 China', countrycode: 'CN', alive: 4, hexcode: '#854BA1' },
        { name: '21 Croatia', countrycode: 'HR', alive: 4, hexcode: '#38B27E' },
        { name: '22 Greece', countrycode: 'GR', alive: 4, hexcode: '#88ABDA' },
        { name: '23 Ukraine', countrycode: 'UA', alive: 4, hexcode: '#E58B65' },
        { name: '24 Romania', countrycode: 'RO', alive: 4, hexcode: '#2C276C' },
        { name: '25 Bulgaria', countrycode: 'BG', alive: 4, hexcode: '#988E09' }
    ]

    const [teams, setTeams] = useState(() => {
        const savedTeams = localStorage.getItem('trackerData');
        if (!savedTeams) return defaultTeams;

        const parsedTeams = JSON.parse(savedTeams);

        // Check if names have changed and update if necessary
        const updatedHexcodes = parsedTeams.map((team, index) => {
            if (index < defaultTeams.length && team.hexcode !== defaultTeams[index].hexcode) {
                return { ...team, hexcode: defaultTeams[index].hexcode };
            }
            return team;
        });

        // If any names were different, update localStorage
        if (JSON.stringify(updatedHexcodes) !== savedTeams) {
            localStorage.setItem('trackerData', JSON.stringify(updatedHexcodes));
        }

        return updatedHexcodes;
    });

    const [history, setHistory] = useState([]);
    
    const handleDecrement = (index) => {
        var newIndex = startIndex + index;
        const updatedTeams = [...teams];
        if (updatedTeams[newIndex].alive > 0) {
            updatedTeams[newIndex].alive = Math.max(updatedTeams[newIndex].alive - 1, 0);
            setTeams(updatedTeams);
            setHistory([...history, { index: newIndex, previousValue: updatedTeams[newIndex].alive + 1 }]);
        }
    }

    const handleIncrement = (index) => {
        var newIndex = startIndex + index;
        const updatedTeams = [...teams];
        if (updatedTeams[startIndex + index].alive < 4) {
            updatedTeams[startIndex + index].alive += 1;
            setTeams(updatedTeams);
            setHistory([...history, { index: newIndex, previousValue: updatedTeams[newIndex].alive - 1 }]);
        }
    }

    const handleUndo = () => {
        if (history.length > 0) {
            const lastAction = history[history.length - 1];
     
            const newButtons = [...teams];
            newButtons[lastAction.index] = {
                ...teams[lastAction.index],
                alive: lastAction.previousValue
            };
            // newCounters[startIndex + lastAction.index].alive = lastAction.previousValue;
            setTeams(newButtons);
            setHistory(history.slice(0, -1));
        }
    };

    const [startIndex, setStartIndex] = useState(() => {
        const savedIndex = localStorage.getItem('currentStartIndex');
        return savedIndex ? parseInt(savedIndex) : 0;
    });

    const [numUkwPlayers, setNumUkwPlayers] = useState(() => {
        const savedUkwPlayers = localStorage.getItem('currentUkwPlayers');
        return savedUkwPlayers ? parseInt(savedUkwPlayers) : 0;
    });

    const displayedTeams = teams.slice(startIndex, startIndex + numTeams);
    const visibleUsers = displayedTeams.filter(team => team.alive > 0);
    const hiddenUsers = displayedTeams.filter(team => team.alive == 0);

    const getTeamCount = () => {
        return displayedTeams.filter((team) => team.alive > 0).length;
    };

    const getAliveCount = () => {
        return displayedTeams.reduce((total, team) => total + team.alive, 0) - numUkwPlayers;
    };

    const countTeamsWithNumAlive = (alive) => {
        return displayedTeams.filter(team => team.alive === parseInt(alive)).length;
    };

    const aliveTeams = getTeamCount();
    const alivePlayers = getAliveCount();
    const numSolos = countTeamsWithNumAlive(1);
    const numDuos = countTeamsWithNumAlive(2);
    const numThreeMan = countTeamsWithNumAlive(3);
    const numFullSquad = countTeamsWithNumAlive(4);

    const [useFlag, setFlag] = useState(() => {
        const disableFlag = JSON.parse(localStorage.getItem('disableFlag'));
        if (disableFlag) {
            return false;
        }
        return true;
    });

    const [usePreset, setPreset] = useState(() => {
        const savedPreset = JSON.parse(localStorage.getItem('currentPreset'));
        if (savedPreset) {
            return true;
        }
        return false;
    });

    const [hideInput, setHiddenInput] = useState(() => {
        const savedInput = JSON.parse(localStorage.getItem('hiddenInput'));
        if (savedInput) {
            return true;
        }
        return false;
    });

    const [showBar, setShowBar] = useState(() => {
        const hiddenBar = JSON.parse(localStorage.getItem('hiddenBar'));
        if (hiddenBar) {
            return false;
        }
        return true;
    });

    const [showLeftBar, setShowLeftBar] = useState(() => {
        const hiddenLeftBar = JSON.parse(localStorage.getItem('hiddenLeftBar'));
        if (hiddenLeftBar) {
            return false;
        }
        return true;
    });

    const resetView = () => {
        const updatedTeams = teams.map(team => ({
            ...team,
            alive: 4
        }));
        setTeams(updatedTeams);
        setNumUkwPlayers(0);
        setHistory([]);
    };

    // const resetTeams = () => {
    //     localStorage.removeItem('trackerData');
    //     setTeams(defaultTeams);
    // };

    function hideBar() {
        if (showBar) {
            setShowBar(false);
        }
        else {
            setShowBar(true);
        }
    }

    function IncreaseUkwPlayer() {
        if (numUkwPlayers < 9 && alivePlayers > 0) {
            setNumUkwPlayers(numUkwPlayers + 1);
        }
    }
    function DecreaseUkwPlayer() {
        if (numUkwPlayers > 0) {
            setNumUkwPlayers(numUkwPlayers - 1);
        }
    }

    useEffect(() => {
        localStorage.setItem('trackerData', JSON.stringify(teams));
        localStorage.setItem('currentUkwPlayers', numUkwPlayers.toString());
        localStorage.setItem('currentStartIndex', startIndex.toString());
        localStorage.setItem('currentNumTeams', numTeams.toString());
        localStorage.setItem('currentPreset', usePreset);
        localStorage.setItem('disableFlag', !useFlag);
        localStorage.setItem('hiddenInput', hideInput);
        localStorage.setItem('hiddenBar', !showBar);
        localStorage.setItem('hiddenLeftBar', !showLeftBar);
    }, [teams, numUkwPlayers, startIndex, numTeams, usePreset, useFlag, hideInput, showBar, showLeftBar]);

    return (
        <div>
            <div className="md:mb-2 border-b border-black">
                <div className="container mx-auto">
                    <div className="flex flex-row flex-wrap justify-center items-center mt-2 mb-2">
                        <div className="flex flex-row flex-wrap xl:ml-[242px]">
                            <p className="mx-4 border border-gray-400"><span className="bg-orange-700 text-white xs:text-2xl md:text-4xl p-2 inline-block">{alivePlayers}</span><span className="xs:text-2xl md:text-4xl py-2 px-3 inline-block">ALIVE</span></p>
                            <p className="mx-4 border border-gray-400"><span className="bg-lime-700 text-white xs:text-2xl md:text-4xl p-2 inline-block">{aliveTeams}</span><span className="xs:text-2xl md:text-4xl py-2 px-3 inline-block">TEAMS</span></p>
                        </div>
                        <div className="w-[242px] mt-2 sm:mt-0 pt-1 flex flex-wrap flex-row justify-between text-center text-lg md:text-xl text-gray-400">
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numSolos}</div>
                            </div>
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numDuos}</div>
                            </div>
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numThreeMan}</div>
                            </div>
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numFullSquad}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap flex-row mb-10 relative">
                <div className={"w-auto pt-3 hidden xl:block pl-6 teamlist " + (showLeftBar ? "" : "xl:hidden")}>
                    {visibleUsers.map((value, index) => {
                        return (
                            <TeamList value={value} index={index} key={"visible-" + startIndex + index} />
                        )
                    })
                    }
                    {hiddenUsers.map((value, index) => {
                        return (
                            <TeamList value={value} index={index} key={"hidden-" + startIndex + index} />
                        )
                    })
                    }
                </div>
                <div className="mx-auto container flex-1">
                    <div className="flex flex-wrap flex-row px-4">
                        {displayedTeams.map((value, index) => {
                            return (
                                <Counter
                                    value={value}
                                    index={startIndex + index}
                                    key={"counter-" + startIndex + index}
                                    useFlags={useFlag}
                                    hideInput={hideInput}
                                    onDecrement={() => handleDecrement(index)}
                                    onIncrement={() => handleIncrement(index)}
                                    changeTeamname={(event) => {
                                        const { name, value } = event.target;
                                        const updatedTeams = [...teams];
                                        updatedTeams[startIndex + index].name = value;
                                        setTeams(updatedTeams);

                                    }}
                                />
                            )
                        })
                        }
                        <div className="w-1/3 md:w-1/4 lg:w-1/5 mt-3 sm:mt-4 md:mt-3 md:mb-2 flex items-center justify-center flex-wrap flex-row">
                            <div className="unknown-btn justify-center flex items-center border border-black sm:rounded-3xl px-2 sm:px-3 shadow-xl h-[66px] sm:h-[86px] xl:h-[95px] 2xl:h-[110px]">
                                <button className={"px-2 bg-red-700 hover:bg-red-600 text-2xl md:text-4xl text-center min-w-[30px] h-[30px] leading-[30px] sm:min-w-[40px] sm:h-[40px] sm:leading-[40px] 2xl:min-w-[50px] 2xl:h-[50px] 2xl:leading-[50px] " + (numUkwPlayers == 0 ? "" : "")}
                                    onClick={() => {
                                        DecreaseUkwPlayer();
                                    }}
                                >&#x2212;</button>
                                <span className="text-2xl sm:text-4xl md:text-5xl inline-block mx-3 md:mx-4 xl:mx-6">{numUkwPlayers}</span>
                                <button className="px-2 bg-lime-700 hover:bg-lime-600 text-2xl md:text-4xl text-center min-w-[30px] h-[30px] leading-[30px] sm:min-w-[40px] sm:h-[40px] sm:leading-[40px] 2xl:min-w-[50px] 2xl:h-[50px] 2xl:leading-[50px]"
                                    onClick={() => {
                                        IncreaseUkwPlayer();
                                    }}
                                >&#x2b;</button>
                            </div>
                            <span className="pt-2 text-lg xl:text-xl text-gray-400 w-full text-center">Unknown death</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:fixed lg:bottom-0 w-full bg-zinc-900 pt-3 lg:pt-0 pb-1 lg:pb-0">
                <div className="container mx-auto relative">
                    <div className={"flex flex-row flex-wrap text-sm md:text-lg justify-center items-center px-4 " + (showBar ? "" : "hidden")}>

                        <a className="text-2xl font-russo mr-4 w-full my-3 lg:my-0 lg:w-auto text-center order-3 lg:order-1" href="https://pubgenie.gg/" target="_blank">PUBGenie</a>
                        {removeTeam(setTeams, teams, setNumTeams, numTeams)}

                        {addTeam(setTeams, teams, setNumTeams, numTeams)}

                        <div className="order-2 my-2 mx-1 md:mx-2 text-center md:w-auto">
                            {startTeamNr(startIndex, setStartIndex)}
                        </div>

                        <div className="order-2 w-full md:w-auto flex justify-center">
                            {iconType(useFlag, setFlag)}

                            {inputToggle(hideInput, setHiddenInput)}

                            {leftBarToggle(showLeftBar, setShowLeftBar)}

                            {loadPreset(usePreset, setPreset, setNumTeams, setStartIndex)}

                            {/* <button className="border text-white p-2 flex items-center mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px]"
                                onClick={resetTeams}>
                                Reset teams
                            </button> */}

                            <button
                                onClick={handleUndo}
                                disabled={history.length === 0}
                                className="border text-white flex items-center p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px] disabled:bg-gray-700 disabled:cursor-not-allowed"
                            >
                                <FontAwesomeIcon icon={faRotateLeft} /><span className='px-1'>Undo</span>
                            </button>

                            <button className="border text-white p-2 flex items-center mx-1 md:mx-2 my-2 bg-red-700 hover:bg-red-600 h-[42px]"
                                onClick={resetView}>
                                Reset
                            </button>
                        </div>
                    </div>

                    <button className="hidden lg:block absolute top-[-36px] left-1/2 right-1/2 -translate-x-1/2 500 text-white inline-block bg-slate-700 hover:bg-slate-800 w-[36px] h-[36px] flex items-center justify-center"
                        onClick={() => {
                            hideBar();
                        }}>
                        <span>&#8616;</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Counters;
