import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faEye, faEyeSlash, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

export const iconType = (useFlag, setFlag) => {
    return <button className="border flex items-center text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px]"
        onClick={() => {
            if (!useFlag) {
                setFlag(true);
            }
            else {
                setFlag(false);
            }
        }}
    >
        {
            useFlag ? (
                <>
                    <FontAwesomeIcon icon={faEye} style={{ color: "#65a30d" }} width={22} /><span className='px-1'>Flags</span>
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#b91c1c" }} width={22} /><span className='px-1'>Flags</span>
                </>
            )
        }

    </button>;
}

export const inputToggle = (hideInput, setHiddenInput) => {
    return <button className="hidden lg:flex items-center h-[42px] border text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800"
        onClick={() => {
            if (!hideInput) {
                setHiddenInput(true);
            }
            else {
                setHiddenInput(false);
            }
        }}
    >
        {
            !hideInput ? (
                <>
                    <FontAwesomeIcon icon={faEye} style={{ color: "#65a30d" }} width={22} /><span className='px-1'>Teamname</span>
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#b91c1c" }} width={22} /><span className='px-1'>Teamname</span>
                </>
            )
        }
    </button>;
}

export const leftBarToggle = (showLeftBar, setShowLeftBar) => {
    return <button className="hidden xl:flex items-center border text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px]"
        onClick={() => {
            if (showLeftBar) {
                setShowLeftBar(false);
            }
            else {
                setShowLeftBar(true);
            }
        }}
    >
        {
            showLeftBar ? (
                <>
                    <FontAwesomeIcon icon={faEye} style={{ color: "#65a30d" }} width={22} /><span className='px-1'>Teamlist</span>
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#b91c1c" }} width={22} /><span className='px-1'>Teamlist</span>
                </>
            )
        }
    </button>;
}

export const startTeamNr = (startIndex, setStartIndex) => {
    return <div className='inline-flex items-center border h-[42px]'>
        <button className={"text-white px-2 h-[40px] bg-amber-800 " + (startIndex > 0 ? "" : "pointer-events-none")}
            onClick={() => {
                if (startIndex > 0) {
                    setStartIndex(startIndex - 1);
                }
            }}
        >      <FontAwesomeIcon icon={faCaretLeft} /></button>
        <span className="ml-2 mr-2">Start slot #{startIndex + 1}</span>
        <button className={"text-white px-2 h-[40px] bg-amber-800 " + (startIndex < 22 ? "" : "pointer-events-none")}
            onClick={() => {
                if (startIndex < 22) {
                    setStartIndex(startIndex + 1);
                }
            }}
        >
            <FontAwesomeIcon icon={faCaretRight} />
        </button>

    </div>;
}

export const removeTeam = (setTeams, teams, setNumTeams, numTeams) => {
    return <button className="order-2 border text-white mx-1 md:mx-2 uppercase flex items-center my-2 group h-[42px]"
        onClick={() => {
            const updatedTeams = [...teams];
            if (updatedTeams[numTeams - 1] != null) {
                setTeams(updatedTeams);
                setNumTeams(numTeams - 1);
            }
        }}
    >
        <div className="h-full px-2 bg-red-700 group-hover:bg-red-600 border-r justify-center text-xl min-w-[40px] flex items-center"><FontAwesomeIcon icon={faMinus} /></div>
        <span className="p-2">team</span>
    </button>;
}

export const addTeam = (setTeams, teams, setNumTeams, numTeams) => {
    return <button className="order-2 border text-white mx-1 md:mx-2 uppercase flex items-center my-2 group h-[42px]"
        onClick={() => {
            const updatedTeams = [...teams];
            if (updatedTeams[numTeams] != null) {
                setTeams(updatedTeams);
                setNumTeams(numTeams + 1);
            }
        }}
    >
        <div className="h-full px-2 bg-lime-700 group-hover:bg-lime-600 border-r justify-center text-xl min-w-[40px] flex items-center"><FontAwesomeIcon icon={faPlus} /></div>
        <span className="p-2">team</span>
    </button>;
}


export const TeamList = ({ value }) => {

    return (
        <div className={"w-full flex flex-wrap flex-row justify-between space-between px-4 py-1 border-b border-l border-r border-black teamname " + (value.alive === 0 ? "text-gray-500 !bg-transparent" : "")}>
            <div className="">{value.name}</div>
            <div className="pl-6">{value.alive}</div>
        </div>
    )
}

export const loadPreset = (usePreset, setPreset, setNumTeams, setStartIndex) => {

    return <button className="border text-white flex items-center p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px]"
        onClick={() => {
            if (!usePreset) {
                setPreset(true);
                setNumTeams(17);
                setStartIndex(2);
            }
            else {
                setPreset(false);
                setNumTeams(16);
                setStartIndex(0);
            }
        }}
    >
        {!usePreset ? "Scrims preset" : "Normal preset"}
    </button>;
}